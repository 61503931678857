/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Seo from "./seo"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({children, wpLang, pageTitle, pageMeta, featuredImagePath, currentPath}) => {
  return (
    <>
      <Seo pageTitle={pageTitle} pageMeta={pageMeta} pageLang={wpLang} featuredImagePath={featuredImagePath}/>
      <Header wpLang={wpLang} currentPath={currentPath} />
      <div className="main-wrap">
        <main id={wpLang}>{children}</main>
      </div>
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
