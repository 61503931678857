import React from "react"
import { Container,Row } from 'react-bootstrap'
import LogoW from "../images/whitelogo.inline.svg"
import { Link } from "gatsby"

const Footer = () => {
  return (
<Row className="footer">
        <Container>
          <div className="footer__widgets">
            <Row>
            <div className="col-lg-3">
            <Link to="/" className="footer-logo"><LogoW/></Link>
            </div>
              <div className="col-lg-9">
                <div className="footer-nav">
                <a href="https://app.giftcard.dagcoin.org/">Shop</a>
                <Link to="support">Support</Link>
                <a href="https://app.giftcard.dagcoin.org/sign-in">SIGN IN</a>
                <a href="https://app.giftcard.dagcoin.org/sign-up">SIGN UP</a>
                </div>
              </div>
            </Row>
          </div>   
        <div className="footer-links">
        <p>Dagcoin Gift Card 2020</p>
         <p>Copyright ©, all rights reserved</p>
         <Link to="/privacy-policy/">Privacy Policy</Link>
         <Link to="/terms-of-service/">Terms of Service</Link>
        </div>    
        </Container>
      </Row>
  )
}
export default Footer